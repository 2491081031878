import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';

// provider import
import { BrowserRouter } from 'react-router-dom'

// assets import
import './assets/css/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);