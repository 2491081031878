import React from 'react';
import logo from 'assets/img/logo-light.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='w-full bg-slate-800'>
            <div className="container max-w-6xl mx-auto px-4 py-6 mt-12">
                <div className="flex items-center justify-center md:justify-start gap-2 mb-2">
                    <img src={logo} className='size-7' alt='tonflix logo' />
                    <h1 className='text-xl text-white font-bold'>Tonflix</h1>
                </div>
                <p className='text-white text-sm md:text-base text-center md:text-start'>Liquid staking protocol and TON stakers community</p>
                <div className="border-t border-slate-600 mt-3 pt-3 flex items-center flex-col md:flex-row gap-2 justify-between">
                    <p className='text-slate-400 text-sm'>© Copyright 2022-2024 Tonflix wallet</p>
                    <div className="flex items-center gap-3">
                        <Link to='https://docs.tonflixwallet.com/legal-agreements/privacy-policy' target='_blank' className='text-sm text-slate-400 hover:text-white transition-colors'>Privacy policy</Link>
                        <Link to='https://docs.tonflixwallet.com/legal-agreements/terms-of-use' target='_blank' className='text-sm text-slate-400 hover:text-white transition-colors'>Terms of use</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;