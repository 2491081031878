import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Hero from './components/hero';
import Benefit from './components/benefit';
import Statistics from './components/statistics';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="../../../public/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../../../public/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../../../public/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../../../public/favicon/site.webmanifest" />
            </Helmet>
            <Header />
            <Hero />
            <Statistics />
            <Benefit />
            <Footer />
        </div>
    );
};

export default HomePage;