import React from 'react';
import walletImage from 'assets/img/wallet.png';

const Hero = () => {
    return (
        <div className='container max-w-6xl mx-auto p-4 mt-12'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div className="col-span-1">
                    <h1 className='font-bold text-4xl md:text-5xl mb-5 !leading-snug text-center md:text-left'>The Best Wallet & Smart Staking Protocol in <span className='text-white bg-blue-500 rounded-2xl px-5 text-nowrap py-1 font-bold'>Ton Chain</span></h1>
                    <h6 className='mb-6 text-xl text-center md:text-left'>Get started Experience a New Kind of Deposit: Always Growing, Fully Tradable, and DeFi Ready</h6>
                    <div className="flex items-center gap-2 justify-center md:justify-start">
                        <a href='https://t.me/tonflixwallet_bot' target='_blank' className='font-bold text-lg md:text-xl px-6 md:px-8 py-3 md:py-4 rounded-2xl text-white bg-slate-800 hover:bg-slate-600 transition-colors'>
                            Stake Now
                        </a>
                        <a href='https://t.me/tonflixapp' target='_blank' className='font-bold text-lg md:text-xl px-6 md:px-8 py-3 md:py-4 rounded-2xl text-slate-800 bg-slate-200/80 hover:bg-slate-300/70 transition-colors'>
                            Community
                        </a>
                    </div>
                </div>
                <div className="col-span-1 relative">
                    <div className="flex items-center justify-center">
                        <img src={walletImage} alt='ton wallet' className='max-w-xs md:max-w-md w-full' />
                    </div>
                    <div className="absolute w-[15vw] h-[15vw] rounded-full bg-violet-600/50 top-0 left-0 -z-50 blur-3xl"></div>
                    <div className="absolute w-[20vw] h-[20vw] rounded-full bg-blue-600/50 top-24 left-1/3 -z-50 blur-3xl"></div>
                </div>
            </div>
        </div>
    );
};

export default Hero;