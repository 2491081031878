import React from 'react';

const Statistics = () => {
    const data = [
        {
            id: 'item-1',
            value: 5,
            prefix: "+",
            suffix: "M",
            title: 'Liquidity Pool',
        },
        {
            id: 'item-3',
            value: 2.8,
            prefix: "+$",
            suffix: "M",
            title: 'Take Profit Archived',
        },
        {
            id: 'item-4',
            value: 50,
            prefix: "+",
            suffix: "k",
            title: 'Active Users',
        },
    ];
    return (
        <div className='container max-w-6xl mx-auto p-4 mt-12'>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
                {data?.map((item) => (
                    <div key={item.id} className="col-span-1 bg-slate-200/50 rounded-2xl p-4 md:p-6">
                        <p className='text-slate-500 font-semibold text-lg'>{item.title}</p>
                        <p className='text-slate-900 font-semibold text-3xl'>{item.prefix}{item.value}{item.suffix}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Statistics;