import React from 'react';

// assets import
import logo from 'assets/img/logo-dark.png'

const Header = () => {
    return (
        <div className='container max-w-6xl mx-auto p-4'>
            <div className="grid grid-cols-2 gap-1 items-stretch py-8">
                <div className="flex items-center gap-1 md:gap-2">
                    <img src={logo} className='size-6 md:size-12' alt='tonflix logo' />
                    <h1 className='text-lg md:text-3xl font-bold'>Tonflix</h1>
                </div>
                <div className="flex justify-end col-span-1">
                    <a href='https://docs.tonflixwallet.com/' target='_blank' className='font-bold text-nowrap text-xs sm:text-sm md:text-xl px-3 md:px-8 py-2 md:py-3 rounded-xl md:rounded-2xl text-slate-800 hover:text-slate-600 transition-colors'>Whitepaper</a>
                    <a href='https://t.me/tonflixwallet_bot' target='_blank' className='font-bold text-nowrap text-xs sm:text-sm md:text-xl px-3 md:px-8 py-2 md:py-3 rounded-xl md:rounded-2xl text-white bg-slate-800 hover:bg-slate-600 transition-colors'>Launch app</a>
                </div>
            </div>
        </div>
    );
};

export default Header;