import React from 'react';
import picture1 from 'assets/img/poster/back.tonflixsite-02.png';
import picture2 from 'assets/img/poster/back.tonflixsite-04.png';
import picture3 from 'assets/img/poster/back.tonflixsite-01.png';

const Benefit = () => {
    const data = [
        {
            id: 'item-1',
            title: 'Provide Liquidity',
            description: 'Contribute your tonflix tokens to liquidity pools in various DeFi protocols to earn additional rewards and support decentralized financial markets.',
            image: picture1,
        },
        {
            id: 'item-2',
            title: 'Trade Strategically',
            description: "Utilize Decentralized Exchanges (DEXs) to trade tonflix tokens, enabling you to capitalize on market opportunities and optimize your returns.",
            image: picture2,
        },
        {
            id: 'item-3',
            title: 'Engage in Lending',
            description: "Participate in lending protocols by locking up your tonflix tokens in smart contracts. This allows other users to borrow them, and you earn interest, enhancing both your earnings and the DeFi ecosystem’s liquidity.",
            image: picture3,
        },
    ];
    return (
        <div className='container max-w-6xl mx-auto p-4 mt-24'>
            {/* <h5 className='lg:text-3xl text-xl font-bold'>The benefits you get</h5> */}
            <div className="flex flex-col gap-16 mt-10">
                {data?.map((item, index) => (
                    <div key={item.id} className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 items-center">
                        <div className={`${index % 2 === 0 ? "md:order-1" : "md:order-2"} `}>
                            <p className='mb-2 text-3xl font-semibold text-center md:text-start'>{item.title}</p>
                            <p className='text-lg max-w-md text-center md:text-start'>{item.description}</p>
                        </div>
                        <div className={`${index % 2 === 0 ? "md:order-2" : "md:order-1"} flex items-center justify-center`}>
                            <div className="flex items-center justify-center">
                                <img src={item.image} alt='tonflix details' className='max-w-xs md:max-w-md' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Benefit;